import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "form",
      "style": {
        "position": "relative"
      }
    }}>{`Form`}</h1>
    <p>{`Forms are used for submitting data. Every input must be displayed with a label.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`When possible, data validation must occur before the user submits the form.`}</li>
      <li parentName="ul">{`Use placeholders to assist entry of numeric fields. Placeholders are visible until the user starts typing.`}</li>
      <li parentName="ul">{`Use mask input that helps the user identify the input pattern.`}</li>
      <li parentName="ul">{`Required fields must be marked with a red asterisk after the label.`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <p>{`The following example don't use any lib to manage the form state. In practice, you may (but not need to) use a third party library to manage form state, like `}<a parentName="p" {...{
        "href": "https://github.com/wsmd/react-use-form-state"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-use-form-state`}</code></a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/final-form/react-final-form"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-final-form`}</code></a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/jaredpalmer/formik"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`formik`}</code></a>{` etc.`}</p>
    <Demo src='pages/components/form/Form' mdxType="Demo" />
    <h2 {...{
      "id": "example-with-react-use-form-state",
      "style": {
        "position": "relative"
      }
    }}>{`Example with `}<a parentName="h2" {...{
        "href": "https://github.com/wsmd/react-use-form-state"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-use-form-state`}</code></a></h2>
    <iframe src='https://codesandbox.io/embed/bold-form-example-use-form-state-s0ptf?autoresize=1&fontsize=14&module=%2Fsrc%2FForm.tsx&view=preview' title='bold-form-example-use-form-state' allow='geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media' style={{
      width: '100%',
      height: '500px',
      border: 0,
      borderRadius: '4px',
      overflow: 'hidden',
      marginBottom: '2rem'
    }} sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>
    <h2 {...{
      "id": "example-with-react-final-form",
      "style": {
        "position": "relative"
      }
    }}>{`Example with `}<a parentName="h2" {...{
        "href": "https://github.com/final-form/react-final-form"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-final-form`}</code></a></h2>
    <iframe src='https://codesandbox.io/embed/bold-form-example-final-form-8pzzd?autoresize=1&fontsize=14&module=%2Fsrc%2FFormExample.tsx&view=preview' title='bold-form-example-final-form' allow='geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media' style={{
      width: '100%',
      height: '500px',
      border: 0,
      borderRadius: '4px',
      overflow: 'hidden',
      marginBottom: '2rem'
    }} sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      